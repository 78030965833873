/* General */
  $color1: white;
  $color2: black;
  $color3: crimson;
  $color4: #111;
  $color5: #222;
  $color6: #d3d3d3;
  $font1: "Poppins", sans-serif;
  $font2: "Ubuntu", sans-serif;
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
  }
  

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #888; /* color of the scroll thumb */
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: f1f1f1 #888;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    color: $color1;
    font-family: $font1;
  }

  a {
    color: inherit;
    text-decoration: none;
    
    &.txt-primary {
      color: $color3;
      transition: 0.25s ease;

      &:hover {
        transform: scale(1.05);
        display: inline-block;
      }
    }
    
    &.txt-secondary {

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h2 {
    font-size: 1rem;
  }
  /* end General */

  /* Structures */
  .wrapper {
    width: 100%;
    max-width: 1300px;
    padding-right: 1.4375em;
    padding-left: 1.4375em;
    margin: 0 auto;
    
    @media screen and (min-width: 700px) {      
    padding-right: 3.125em;
    padding-left: 3.125em;
    }

    @media screen and (min-width: 1000px) {   
      padding-right: 5em;
      padding-left: 5em;
    }
  }
  /* end Structures */

  /* Sections */
  section {
    padding: 6.25em 0;

    header {
      display: flex;
      justify-content: center;

      h2 {
        font-size: 2.5rem;
        font-family: $font2;
        font-weight: 500;
        position: relative;
        display: inline-block;
        text-align: center;
  
        &::before {
          content: "";
          height: 3px;
          background: $color2;
          width: 100%;
          position: absolute;
          top: 125%;
          left: 50%;
          transform: translateX(-50%);
        }
  
        .theme-black &::before {
          background: $color1;
        }
  
        &::after {
          font-size: 1.25rem;
          color: $color3;
          background: $color1;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          padding: 0 0.25em;
          white-space: nowrap;
        }
  
        .theme-black &::after {
          background: $color4;
        }
      }
    }

    h3 {
      font-size: 1.1875rem;
      font-weight: 600;

      @media screen and (min-width: 500px) {
        font-size: 1.1875rem;
      }
    }

    .text {
      text-align: justify;
    }

    .text p {
      margin-top: 0.5263em;
    }

    .text .btn {
      margin-top: 1em;
    }
  }
  /* end Sections */

  /* Thèmes */
  .theme-white {
    color: $color2;
  }
  
  .theme-black {
    background: $color4;
    color: $color1;

    a {
      color: $color1;

      &:hover {
        color: $color3;
      }
    }
  }
  /* end Thèmes */

  /* Content */
  .color-emphased {
    color: $color3;
    transition: 0.25s ease;
  }

  .btn {
    display: inline-block;
    font-size: 1.25rem;
    color: $color1;
    background: $color3;
    padding: 0.5em 1.5em;
    margin-top: 1em;
    border-radius: 6px;
    border: 2px solid $color3;
    transition: 0.25s ease;
    text-align: center;
    
    @media screen and (min-width: 700px) {
      font-size: 1.4375rem;
    }

    @media screen and (min-width: 1000px) {
      font-size: 1.5625rem;
    }

    &:hover {
      background: transparent;
      color: $color3;
    }
  }

  .btn-off {
    background: transparent;
    color: $color3;
    
    @media screen and (min-width: 700px) {
      font-size: 1.4375rem;
    }

    @media screen and (min-width: 1000px) {
      font-size: 1.5625rem;
    }

    &:hover {
      background: $color3;
      color: inherit;
    }
  }

  ul.list li {
    list-style-type:disc;
  }

  .txt-nowrap {
    white-space: nowrap;
  }

  .hyphens-man {
    hyphens: manual;
  }
  /* end Content */
  