.header-container {
  position: fixed;
  width: 100%;
  transition: 0.5s ease;
  padding: 1.875em 0;
  z-index: 1;

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $font2;
    }
}

.header-on-scroll {
  background: $color3;
  padding-top:0.9375em;
  padding-bottom:0.9375em;

    .menu-container ul a {
      &:hover {
        color: inherit;
      }
    }
}