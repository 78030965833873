.experiences-container {

    header {
        h2 {
            &::after {
                content: "mon parcours";
            }
          }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5em;
    }

    .experiences {
        display: flex;
        flex-direction: column;
        gap: 1.25em;

        @media screen and (min-width: 800px) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }

        .experience {
            background: $color5;
            border-radius: 0.375em;
            text-align: center;
            padding: 3.125em 1.5625em;
            transition: 0.25s ease;
    
            @media screen and (min-width: 800px) {
                flex-basis: calc(50% - 1.25em);
            }
    
            .logo-container {
                display: flex;
                justify-content: center;
                align-items: center;
        
                @media screen and (min-width: 800px) {
                    height: 125px;
                }
    
                .logo-pbx, .logo-dkt {
                    width: 220px;
        
                    @media screen and (min-width: 800px) {
                        width: 190px;
                    }
                }
        
                .logo-awk {
                    width: 150px;
        
                    @media screen and (min-width: 800px) {
                        width: 140px;
                    }
                    
                }
    
                .logo-dkt {
                    background-color: white;
                }
                
                a {
                    cursor: auto;
                    font-family: $font2;
                    margin-top: 0;
                }
            }
    
    
            h3 {
                font-size: 1.5625rem;
                font-weight: 500;
                margin-top: 0.4em;
            }
    
            h4 {
                font-weight: 500;
            }
    
            ul {
                margin-top: 0.625em;
                padding-left: 1em;
                text-align: left;
    
                li {
                    margin-top: 0.3125em;
                }
            }
    
            a {
                display: block;
                margin-top: 1.125em;
            }
        }

    }

    form {
        display: none;
    }

}