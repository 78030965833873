.about-container {

    h2 {
        &::after {
            content: "Qui suis-je ?";
        }
      }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5em;
        gap: 3.75em;

        @media screen and (min-width: 950px) {
            flex-direction: row;
            gap: 5%;
        }

        img {
            width: 250px;
            height: 250px;
            object-fit: cover;
            border-radius: 0.375em;
            flex: 1 0 250px;

            @media screen and (min-width: 400px) {
                width: 400px;
                height: 400px;
                flex: 1 0 400px;
            }

            @media screen and (min-width: 1100px) {
                width: 400px;
                height: 400px;
                flex: 1 0 400px;
            }
        }

        h3 {
            @media screen and (min-width: 500px) {
                font-size: 1.5625rem;
            }
        }

        .cta {
            text-align: center;
    
            @media screen and (min-width: 950px) {
            text-align: left;
            }
            
            .btn {
                @media screen and (min-width: 700px) {
                    font-size: 1.25rem;
                }
            }
        }
    }


}