.hero-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $font2;

    @media screen and (max-width: 519px) {
        background: -webkit-image-set( url("../../../public/img/hero-bg-520w.jpg") 1x, url("../../../public/img/hero-bg-1040w.jpg") 2x) no-repeat center/cover fixed;
        background: image-set( url("../../../public/img/hero-bg-520w.jpg") 1x, url("../../../public/img/hero-bg-1040w.jpg") 2x) no-repeat center/cover fixed;
    }

    @media screen and (min-width: 520px) {
        background: -webkit-image-set( url("../../../public/img/hero-bg-1040w.jpg") 1x, url("../../../public/img/hero-bg-1560w.jpg") 1.5x) no-repeat center/cover fixed;
        background: image-set( url("../../../public/img/hero-bg-1040w.jpg") 1x, url("../../../public/img/hero-bg-1560w.jpg") 1.5x) no-repeat center/cover fixed;
    }

    @media screen and (min-width: 1000px) {
        background: url("../../../public/img/hero-bg-laurent-dubosq-1680w.jpg") no-repeat center/cover fixed;
    }

    p {
        font-size: 1.6875rem;
    }

    h1 {
        font-size: 3.125rem;
        transform: translateX(-3px);

        @media screen and (min-width: 500px) {
            font-size: 3.75rem;
        }

        @media screen and (min-width: 700px) {
            font-size: 4.375rem;
        }

        @media screen and (min-width: 950px) {
            font-size: 4.6875rem;
        }
    }

    div {
        margin: 0.1851em 0;
        font-size: 1.6875rem;

        @media screen and (min-width: 501px) {
            font-size: 2rem;
        }

        @media screen and (min-width: 700px) {
            font-size: 2.1875rem;
        }

        @media screen and (min-width: 950px) {
            font-size: 2.5rem;
        }

        .jobs-writer {
          display: inline;
          font-size: inherit;
        }

        .jobs-writer::after {
            content: "|";
            animation: text-anim 1s infinite;

            @keyframes text-anim {
              50% {
                opacity: 0;
                }
              100% {
                  opacity: 1;
              }
            }
        }
    
        .color-emphased {
          font-weight: 500;
        }
    }

    .ctas {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 1em;

        a {
          margin-top: 0;
        }
    }
}